<!-- 购物车 -->
<script setup>
  import cartList from './compontent/list'
  import { getCurrentInstance, onMounted, ref, reactive, nextTick } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()

  const cartListRef = ref()

  const getCartList = () => {
    nextTick()
    cartListRef.value.queryCartPro()
  }

  onMounted(() => {
    getCartList()
  })
</script>

<template>
  <div class="page-content">
    <cartList ref="cartListRef" />
  </div>
</template>

<style lang="less" scoped>
  .page-content {
    position: relative;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 155px - 170px);
    margin: 0 auto;
  }
</style>
